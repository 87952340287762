/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable camelcase */
import {Text} from '@deity-io/falcon-ui';
import clsx from 'clsx';
import React, {useState} from 'react';
import {CloseMainDropdownContext} from '../../../context/basicContexts';
import {NavbarProvider} from '../../../context/NavbarContext';
import useMenuNavbarItems from '../../../hooks/useMenuNavbarItems';
import useResponsive from '../../../hooks/useResponsive';
import ExpandArrow from './ExpandArrow';
import './MenuNavbar.scss';
import MenuNavbarLink from './MenuNavbarLink';
import MenuNavbarSection from './MenuNavbarSection';

const InteractiveDiv = ({ className, onTouchStart, onMouseEnter, onMouseLeave, children, disabledEl }) => {
  const { isMobile } = useResponsive();

  if (disabledEl == true) {
    return ''
  }

  if (isMobile) {
    return <div className={className}>{children}</div>;
  }

  return (
    <div className={className} onTouchStart={onTouchStart} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {children}
    </div>
  );
};

const MenuNavbarColumn = ({ elements }) => {
  return (
    <div className="menu-navbar__column">
      {elements.map(({ id, title, color = 'auto', url, item_type, type }) => (
        <MenuNavbarLink
          key={id}
          className="menu-navbar__column-item"
          url={url}
          item_type={item_type}
          type={type}
          active
        >
          <Text css={{ color }}>{title}</Text>
        </MenuNavbarLink>
      ))}
    </div>
  );
};

const MenuNavbarRow = ({ row }) => {
  const [column, section] = row.elements;
  const columnElements = column && column.elements && column.elements.filter(elem => !elem.disabled);
  const sectionElements = section && section.elements && section.elements.filter(elem => !elem.disabled);

  return (
    <div className="menu-navbar__row">
      {columnElements && columnElements.length > 0 && <MenuNavbarColumn elements={columnElements} />}
      {sectionElements && sectionElements.length > 0 && <MenuNavbarSection elements={sectionElements} />}
    </div>
  );
};

const DropdownItem = ({ item }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const { title, elements, color = 'auto', url, item_type, type, disabled } = item;
  const filtered = elements && elements.filter((elem) => {
    if (elem !== null) {
      return !elem.disabled
    }

    return false;
  });

  const hasElements = filtered && filtered.length > 0;
  const hasRows = hasElements && elements[0].type === 'row';
  const hasColumn = hasElements && !hasRows;

  return (
    <InteractiveDiv
      disabledEl = {disabled}
      className="menu-navbar__dropdown-item-wrapper"
      onTouchStart={() => setShowDropdown(true)}
      onMouseEnter={() => setShowDropdown(true)}
      onMouseLeave={() => setShowDropdown(false)}
    >
      <div className="menu-navbar__dropdown-item-container">
        <MenuNavbarLink
          className="menu-navbar__dropdown-item"
          url={url}
          item_type={item_type}
          type={type}
          active
          setShowDropdown={setShowDropdown}
        >
          <p className="menu-navbar__dropdown-item-text" style={{ color }}>
            {title}
          </p>
        </MenuNavbarLink>

        {hasElements && <ExpandArrow showDropdown={showDropdown} setShowDropdown={setShowDropdown} />}
      </div>

      {showDropdown && hasElements && (
        <div className="menu-navbar__dropdown-2">
          {hasRows && filtered.map(row => <MenuNavbarRow key={row.id} row={row} />)}
          {hasColumn && <MenuNavbarColumn elements={filtered} />}
        </div>
      )}
    </InteractiveDiv>
  );
};

const NavbarItem = ({ item }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const { title, elements, color = 'auto', url, item_type, type } = item;
  const hasElements = elements && elements.length > 0;

  return (
    <InteractiveDiv
      className="menu-navbar__item-relative"
      onTouchStart={() => setShowDropdown(true)}
      onMouseEnter={() => setShowDropdown(true)}
      onMouseLeave={() => setShowDropdown(false)}
    >
      <div className={clsx('menu-navbar__item-wrapper', hasElements && 'grey-background')}>
        <MenuNavbarLink
          className="menu-navbar__item"
          url={url}
          item_type={item_type}
          type={type}
          setShowDropdown={setShowDropdown}
          parent
        >
          <p className="menu-navbar__item-text" style={{ color }}>
            {title}
          </p>
        </MenuNavbarLink>

        {hasElements && <ExpandArrow showDropdown={showDropdown} setShowDropdown={setShowDropdown} parent />}
      </div>

      {showDropdown && hasElements && (
        <CloseMainDropdownContext.Provider value={() => setShowDropdown(false)}>
          <NavbarProvider>
            <div className="menu-navbar__dropdown-1">
              {elements.map(elem => (
                <DropdownItem key={elem.id} item={elem} />
              ))}
            </div>
          </NavbarProvider>
        </CloseMainDropdownContext.Provider>
      )}
    </InteractiveDiv>
  );
};

const MenuNavbar = ({ isMenuExpanded, setIsMenuExpanded }) => {
  const items = useMenuNavbarItems();

  if (!items) {
    return <></>;
  }

  return (
    <div
      className={clsx('menu-navbar__wrapper', isMenuExpanded && 'show-menu')}
      onClick={e => {
        if (e.currentTarget.isSameNode(e.target)) {
          setIsMenuExpanded(false);
        }
      }}
    >
      <NavbarProvider setIsMenuExpanded={setIsMenuExpanded}>
        <div className="menu-navbar">
          {items.map(item => (
            <NavbarItem key={item.id} item={item} />
          ))}
        </div>
      </NavbarProvider>
    </div>
  );
};

export default React.memo(MenuNavbar);
