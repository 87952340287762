/* eslint-disable camelcase */
import { Link } from '@deity-io/falcon-ui';
import React, { useContext, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { CloseMainDropdownContext } from '../../../context/basicContexts';
import { useNavbarContext } from '../../../context/NavbarContext';
import useResponsive from '../../../hooks/useResponsive';

const isExternalLink = url => {
  // Assume that it's external - because if it isn't - it's going to break in a way that it can recover automatically
  try {
    const currentUrl = new URL(url);
    return currentUrl.host !== window.location.host;
  } catch (e) {
    return true;
  }
};

const extractPath = url => {
  // In case we get an invalid URL - just return it as is (Assume it's a path)
  try {
    const currentUrl = new URL(url);
    return currentUrl.pathname.substring(1);
  } catch (e) {
    return url;
  }
};

const generateLink = (beautified_url, item_type, type) => {
  if (type === 'row' || type === 'column') return;

  let outputLink = beautified_url;

  // Assume these are fine
  if (item_type !== 'custom' && item_type !== 'page') {
    return outputLink;
  }

  switch (item_type) {
    case 'custom':
      // Check that we don't have a slash at the end
      if (outputLink.charAt(outputLink.length - 1) === '/') {
        outputLink = outputLink.slice(0, -1);
      }

      // Remove leading / because we add it later - causing a // - this is user-input
      if (outputLink.charAt(0) === '/') {
        outputLink = outputLink.substring(1);
        break;
      }

      // If it doesn't point to the same host - then forward outside
      if (isExternalLink(beautified_url)) {
        outputLink = `/${outputLink}`;
      }

      break;

    case 'page':
      // Page is anything magento considers a page - hence it should be configured to work locally
      outputLink = extractPath(outputLink);
      break;

    default:
      break;
  }

  return outputLink;
};

const MenuNavbarLink = ({ children, className, url, item_type, type, setShowDropdown, parent }) => {
  const { isMobile } = useResponsive();
  const generatedLink = useMemo(() => generateLink(url, item_type, type), []);
  const { setIsMenuExpanded } = useNavbarContext();

  const closeMainDropdown = useContext(CloseMainDropdownContext);

  if (generatedLink === '/') {
    return (
      <NavLink
        className={className}
        to="#"
        onClick={e => {
          if (setShowDropdown) {
            setShowDropdown(last => !last);
          }
          e.preventDefault();
        }}
      >
        {children}
      </NavLink>
    );
  }

  return (
    <NavLink
      className={className}
      activeClassName={parent && (!isMobile ? 'active-parent' : 'active-parent__mobile')}
      to={`/${generatedLink}`}
      onClick={() => {
        setIsMenuExpanded(false);
        closeMainDropdown();
      }}
    >
      {children}
    </NavLink>
  );
};

export default MenuNavbarLink;
