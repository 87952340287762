import gql from 'graphql-tag';
import { useMemo } from 'react';
import { useQuery } from 'react-apollo';

const GET_MENU_STRUCTURE = gql`
  fragment Items on MenuComponent {
    url: beautified_url
    category_id
    custom_src
    disabled: disable_element
    id
    item_type
    title
    color: title_color
    type
    content
  }
  query menu($identifier: String!) {
    menu(identifier: $identifier) {
      profile {
        elements {
          ...Items
          elements {
            ...Items
            elements {
              ...Items
              elements {
                ...Items
                elements {
                  ...Items
                  elements {
                    ...Items
                    elements {
                      ...Items
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const useMenuNavbarItems = () => {
  const { data } = useQuery(GET_MENU_STRUCTURE, {
    errorPolicy: 'all',
    variables: { identifier: 'top-menu' },
  });

  const elements = data && data.menu && data.menu.profile && data.menu.profile.elements;

  const filtered = useMemo(() => {
    return elements && elements.filter(({ disabled }) => !disabled);
  }, [elements]);

  return filtered;
};

export default useMenuNavbarItems;
