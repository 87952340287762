/* eslint-disable camelcase */
import React from 'react';
import MenuNavbarLink from './MenuNavbarLink';

const sectionColors = ['#ed0084', '#448ccb', '#8dc63f'];

const SectionItem = ({ item, idx }) => {
  const {
    elements: [{ url, item_type, type }, { custom_src, title }, { content }],
  } = item;

  let imageUrl = custom_src.startsWith('/') ? `https://s3.emmezeta.hr${custom_src}` : custom_src;

  imageUrl = imageUrl.toLowerCase().endsWith('.webp') ? imageUrl : imageUrl+'.webp';

  return (
    <div className="menu-navbar__section-item-container" style={{ borderLeftColor: sectionColors[idx] }}>
      <MenuNavbarLink className="menu-navbar__section-item" url={url} item_type={item_type} type={type}>
        <p className="menu-navbar__section-item-title" style={{ color: sectionColors[idx] }}>
          {title}
        </p>

        {imageUrl && <img className="menu-navbar__section-item-image" src={imageUrl} alt="" />}

        {content && <div className="menu-navbar__section-content" dangerouslySetInnerHTML={{ __html: content }} />}
      </MenuNavbarLink>
    </div>
  );
};

const MenuNavbarSection = ({ elements }) => {
  return (
    <div className="menu-navbar__section">
      {elements.map((item, idx) => (
        <SectionItem key={item.id} item={item} idx={idx} />
      ))}
    </div>
  );
};

export default MenuNavbarSection;
