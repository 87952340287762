import clsx from 'clsx';
import React from 'react';
import { useNavbarContext } from '../../../context/NavbarContext';
import useResponsive from '../../../hooks/useResponsive';

const ExpandArrow = ({ showDropdown, setShowDropdown, parent }) => {
  const { isMobile } = useResponsive();
  const { current } = useNavbarContext();

  if (!isMobile) {
    return null;
  }

  const onClickHandler = event => {
    event.stopPropagation();
    setShowDropdown(last => !last);

    if (current.lastSetShowDropdown && current.lastSetShowDropdown !== setShowDropdown) {
      current.lastSetShowDropdown(false);
    }

    current.lastSetShowDropdown = setShowDropdown;
  };

  const className = clsx('expand-arrow-container', {
    'expand-arrow-container__active': parent,
    'expand-arrow-container__current': showDropdown,
  });

  return (
    <button type="button" className={className} onClick={onClickHandler}>
      <div className="expand-arrow" />
    </button>
  );
};

export default ExpandArrow;
