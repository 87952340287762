import React, { createContext, useContext, useMemo, useRef } from 'react';

const Context = createContext();

export const useNavbarContext = () => {
  return useContext(Context);
};

export const NavbarProvider = ({ children, setIsMenuExpanded }) => {
  const { current } = useRef({});
  const prevContext = useNavbarContext();

  if (prevContext && prevContext.setIsMenuExpanded) {
    ({ setIsMenuExpanded } = prevContext);
  }

  const value = useMemo(
    () => ({
      current,
      setIsMenuExpanded,
    }),
    [setIsMenuExpanded],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
